<script setup>
import { watchEffect, onUnmounted } from 'vue'
import { createDebugRecorder } from '@/common/debug-print.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
})
const emits = defineEmits([
  'load-start',
  'load-end',
  'close-handle',
])

const { debugRecord, dispose } = createDebugRecorder('HomeCartNotifyPopover')

async function fetchPopoverData() {
  debugRecord(`fetchPopoverData START`)
  emits('load-start')
  await window._gb_cart_tag_tips_.preRequest()
  debugRecord(`fetchPopoverData END`)
  emits('load-end', 1)
}
async function showPopover() {
  debugRecord(`showPopover START`)
  const showTimeReturns = await window._gb_cart_tag_tips_.showtime({
    callback: (
      ...args
    ) => {
      // 气泡已经展示结束了 或者 没有气泡
      // 执行其他业务逻辑
      debugRecord(`showPopover callback`, { args })
      emits('close-handle', 'any')
    }
  })
  debugRecord(`showtime returns:`, showTimeReturns)
  // hack 无法直接判断出是否有气泡，依赖回调函数返回内容
  if(showTimeReturns === '有标签/有气泡') {
    Monitor.metricFloatComp({
      tags: {
        track_type: '4',
        layer_type: 'HomeCartNotifyPopover',
      },
    })
  }
}

(async () => {
    debugRecord(`isShow: ${props.isShow}`)
    await fetchPopoverData()
})()

watchEffect(async () => {
  if (props.isShow) {
    debugRecord(`isShow: true`)
    await showPopover()
  }
})

onUnmounted(() => {
  dispose()
})
</script>

<template>
  <wbr />
</template>

<style lang="less">

</style>
