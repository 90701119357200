<script lang="jsx">
import { mapState } from 'vuex'
import schttp from 'public/src/services/schttp'
import { transformImg } from '@shein/common-function'
const { GB_cssRight, PUBLIC_CDN, langPath, SiteUID } = gbCommonInfo
import CountDown from './CountDown.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { clearPoskey } from '@shein-aidc/basis-abt-router'
import { abtservice } from 'public/src/services/abt'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

daEventCenter.addSubscriber({ modulecode: '2-35' })
export default {
  name: 'UnpaidCom',
  components: {
    CountDown
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    bottom: { // 距离底部距离
      type: String,
      default: '10rem'
    },
    scene: { // 场景值
      type: String,
      default: ''
    },
    zIndex: {
      type: Number,
      default: 9
    },
    isFold: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      memberId: '', // 用户id
      item: null,
      itemSeconds: null, // 第二份数据
      language: {},
      fadeInFlag: false,
      isComShowFlag: false,
      abtFlag: false,
      type: 0, // 1: 组件展示，2: 组件不展示，数据为空或者接口请求异常，3: abt为关闭
      countDownEndFlag: false, // 是否倒计时结束
      secondsNum: 0
    }
  },
  computed: {
    ...mapState('root', ['userInfo']),
    realShow () {
      return this.isShow && this.isComShowFlag && this.abtFlag
    },
    img () {
      let list = this.item?.order_goods_list || []
      if (list.length == 1) return list?.[0]?.product?.goods_img || ''
      list = list.filter(_ => !(_.is_prime_goods || _.is_xtra_goods))
      return list?.[0]?.product?.goods_img || ''
    },
    timeSeconds () {
      const endTime = Number(this.item?.expire_countdown || 0)
      const nowTime = Date.parse(new Date()) / 1000
      if (endTime > nowTime) {
        return endTime - nowTime
      }
      return 0
    },
    isShowFreeShipping () {
      if (+(this.item?.shipping_price?.amount) == 0) return true
      return false
    },
    saveAmountInfo () {
      return this.item?.total_discount_new || {}
    },
    benefitList () {
      let benefits = []
      if (this.isShowSaveAmount) {
        benefits.push('discount')
      }
      if (this.isShowFreeShipping) {
        benefits.push('freeshipping')
      }
      return benefits.join('`')
    },
    baseAnalyticsData () {
      return {
        order_id: this.item?.billno || '',
        benefit_list: this.benefitList,
        component_style: this.fadeInFlag ? 'pickup' : 'unfold',
        disaplay_style: 'new'
      }
    },
    isShowSaveAmount () {
      if (+this.saveAmountInfo?.amount == 0) return false
      if (['pwfr', 'mpl'].includes(SiteUID)) return false
      return true
    }
  },
  watch: {
    userInfo: {
      handler (newVal, oldVal) {
        // 判断是否登录回来，如果是登录回来，重新获取ABT信息
        if (newVal?.member_id && !oldVal?.member_id && Object.keys(oldVal || {}).length) {
          this.getAbtInfo(true)
        }
      },
      deep: true
    },
    realShow(val) {
      if(val) {
        Monitor.metricFloatComp({
          tags: {
            track_type: '3',
            layer_type: 'UnpaidCom',
          },
        })
      }
    }
  },
  async mounted () {
    this.fadeInFlag = this.isFold
    // this.type = this.isShow ? 1 : 3
    this.$emit('load-start')
    await Promise.all([
      this.getVerifyOrderItem(),
      this.getAbtInfo()
    ])
    this.item && (this.isComShowFlag = true)
    this.sendSceneAnalytics()
    this.loadEnd()
  },
  methods: {
    // 组件加载完成
    loadEnd () {
      !this.abtFlag && (this.type = 3)
      this.$emit('load-end', this.type)
    },
    // 场景埋点
    sendSceneAnalytics () {
      if (this.item?.billno) {
        daEventCenter.triggerNotice({
          daId: '2-35-3',
          extraData: {
            scenes: 'unpaidOrder',
            order_id: this.item.billno,
            unpaid_scene: this.scene,
            benefit_list: this.benefitList,
            disaplay_style: 'new'
          }
        })
      }
    },
    // 曝光埋点
    exposeAnalytics () {
      daEventCenter.triggerNotice({
        daId: '2-35-1',
        extraData: this.baseAnalyticsData
      })
    },
    // 点击埋点
    clickAnalytics (button_type) {
      return {
        id: '2-35-2',
        data: {
          ...this.baseAnalyticsData,
          button_type
        }
      }
    },
    // 更新数据
    async update (cb) {
      await this.getVerifyOrderItem(this.item?.billno || '')
      cb && cb(this.item)
    },
    // 收起组件
    packUpHandle () {
      this.fadeInFlag = true
      this.exposeAnalytics()
    },
    async getAbtInfo (loginChange = false) {
      loginChange && clearPoskey({ posKeys: 'unpaidOrderCom' })
      // eslint-disable-next-line
      const data = await abtservice.getUserAbtResult({ newPosKeys: 'unpaidOrderCom' })
      this.abtFlag = data?.unpaidOrderCom?.param?.unpaidOrder == 'TypeA'
    },
    // 获取数据
    async getVerifyOrderItem (billno = '') {
      try {
        const res = await schttp({
          method: 'POST',
          url: '/api/orders/query/unpaidCom/query',
        })
        if (res?.code == 0) {
          this.language = res.language
          const results = res?.data || null
          if (billno && (results?.billno != billno)) {
            this.itemSeconds = results
            this.exposeAnalytics()
            return
          }
          this.item = results
          this.countDownEndFlag = false
          this.exposeAnalytics()
          this.type = 1
        } else {
          this.type = 2
        }
      } catch (error) {
        this.type = 2
      }
    },
    // 倒计时关闭
    countDownEndHandle () {
      if (this.itemSeconds) {
        this.item = JSON.parse(JSON.stringify(this.itemSeconds))
        this.itemSeconds = null
        return
      }
      this.countDownEndFlag = true
      this.closeHandle()
    },
    // 关闭组件
    closeHandle () {
      this.isComShowFlag = false
      this.$emit('close-handle', this.countDownEndFlag ? 'time' : 'active')
      Monitor.metricFloatComp({
        tags: {
          track_type: '4',
          layer_type: 'UnpaidCom',
        },
      })
    },
    // 去支付
    payNowHandle () {
      const orderType = this.item?.order_type || 0
      if (!orderType) return
      const query = `?goBack=1${this.item.payment_method == 'cod' ? '' : '&show_error_guide_payment=1'}&from_type=4`
      if ([1, 2].includes(orderType)) { // 实物订单
        this.$router.push(`${langPath}/user/orders/detail/${this.item.billno}${query}`)
        return
      }
      if ([4, 5].includes(orderType)) { // 虚拟订单
        this.$router.push(`${langPath}/user/orders/virtualDetail/${this.item?.billno}${query}`)
        return
      }
      if (orderType == 3) { // 礼品卡订单
        this.$router.push(`${langPath}/user/orders/giftcard_detail/${this.item?.billno}`)
        return
      }
    }
  },
  render () {
    // 图片信息
    const unpaidComImg = () => {
      return (
        <div class="unpaid-com__wrap-imgs">
          <img
            class="imgs-main"
            src={transformImg({ img: this.img })}
          />
          {this.timeSeconds && this.fadeInFlag
            && <div class="imgs-equity imgs-equity__fade">
              <CountDown
                time-seconds={this.timeSeconds - this.secondsNum}
                theme="white"
                vOn:countdown-end={this.countDownEndHandle}
                vOn:update-data={this.update}
              />
            </div>
            || ''
          }
          {this.item?.order_goods_num > 1 && !this.fadeInFlag && <div class="imgs-more"></div>}
          {this.item?.order_goods_num > 1 && !this.fadeInFlag  && <div class="imgs-equity">+ {this.item.order_goods_num}</div>}
        </div>
      )
    }

    const saveAmountEl = () => {
      return (
        <div class="unpaid-com__details-amount">
          {this.language.SHEIN_KEY_PWA_29458} <span>{this.saveAmountInfo?.amountWithSymbol || ''}</span>
        </div>
      )
    }

    // 订单详细信息
    const detailsUnpaid = () => {
      return (
        <div
          class="unpaid-com__wrap-details"
        >
          <div class="unpaid-com__details-left">
            <p class="unpaid-com__details-top">
              {this.language.SHEIN_KEY_PWA_26690}
            </p>
            <div class="unpaid-com__details-bottom">
              {this.timeSeconds
                && <CountDown
                  time-seconds={this.timeSeconds}
                  vOn:countdown-end={this.countDownEndHandle}
                  vOn:update-data={this.update}
                  vOn:count-func={() => this.secondsNum += 1}
                />
                || ''
              }
              {this.isShowSaveAmount && <div class="unpaid-com__details-line"></div>}
              {this.isShowSaveAmount && saveAmountEl()}
            </div>
          </div>
          <div
            class={`unpaid-com__details-animation unpaid-com__details-right ${this.isShowFreeShipping ? 'unpaid-com__details-shipping' : ''}`}
            vOn:click_stop={this.payNowHandle}
            v-tap={this.clickAnalytics('pay')}
          >
            {
              this.isShowFreeShipping &&
              <p class="details-right__freeshipping">
                <span class="details-right__freeshipping-txt">{this.language.SHEIN_KEY_PWA_29304}</span>
                <span class="details-right__freeshipping-tips"></span>
              </p>
            }
            <p class="details-right__txt">{this.language.SHEIN_KEY_PWA_26689}</p>
          </div>
        </div>
      )
    }

    // 默认展示样式
    const defaultEl = () => {
      return (
        <transition name="unpaid-fade">
          {
            !this.fadeInFlag && this.realShow && <div
              v-tap={this.clickAnalytics('body')}
              class="unpaid-com__wrap"
              style={`bottom:${this.bottom};z-index:${this.zIndex}`}
              key={this.item?.billno || ''}
              vOn:click={this.payNowHandle}
            >
              <img
                v-tap={this.clickAnalytics('close')}
                class="unpaid-com__wrap-close"
                vOn:click_stop={this.closeHandle}
                src={`${PUBLIC_CDN}${GB_cssRight
                  ? '/pwa_dist/images/unpaid-com__close-ar-dcd2622576.png'
                  : '/pwa_dist/images/unpaid-com__close-437ce28e68.png'}
                `}
              />
              {unpaidComImg()}
              {detailsUnpaid()}
            </div>
          }
        </transition>
      )
    }

    // 收起样式
    const packUpEl = () => {
      return (
        <transition name="min-fade">
          {
            this.fadeInFlag && this.realShow && <div
              v-tap={this.clickAnalytics('body')}
              class="unpaid-com__wrap unpaid-com__wrap-packup"
              style={`bottom:${this.bottom};z-index:${this.zIndex}`}
              key={this.item?.billno || ''}
              vOn:click={this.payNowHandle}
            >
              <img
                v-tap={this.clickAnalytics('close')}
                class="unpaid-com__wrap-close"
                vOn:click_stop={this.closeHandle}
                src={`${PUBLIC_CDN}${GB_cssRight
                  ? '/pwa_dist/images/unpaid-com__close-ar-dcd2622576.png'
                  : '/pwa_dist/images/unpaid-com__close-437ce28e68.png'}
                `}
              />
              {unpaidComImg()}
              <div
                class="unpaid-com__wrap-btn"
              >
                <span>{this.language.SHEIN_KEY_PWA_26689}</span>
              </div>
            </div>
          }
        </transition>
      )
    }

    return (
      <section>
        {packUpEl()}
        {defaultEl()}
      </section>
    )
  }
}
</script>

<style lang="less">
.unpaid-com {
  &__wrap {
    position: fixed;
    right: 0;
    width: 7.8133rem;
    padding: .2133rem;
    border-radius: .1333rem 0 0 .1333rem;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-family: "SF UI Text";
  }
  &__wrap-packup {
    width: 1.3867rem;
    min-width: 1.3867rem;
    max-width: 1.3867rem;
    padding: .0533rem;
    flex-direction: column;
    border-width: 0;
    border-radius: 0;
    .unpaid-com__wrap-imgs {
      margin-right: 0;
      .imgs-main {
        width: 1.28rem;
      }
    }
  }
  &__wrap-btn {
    width: 1.28rem;
    height: .4267rem;
    padding: .04rem .08rem;
    background-color: #fff;
    color: #000;
    font-size: 10px;
    border-radius: .2133rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    span {
      display: inline-block;
      transform: scale(8/10);
      transform-origin: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__wrap-details {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__details-top {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: .1867rem;
  }
  &__details-bottom {
    font-size: 10px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, .3);
    .count-down__time{
      background: #FA6338;
    }
    .count-down__delimiter{
      color: #FA6338;
      font-family: Avenir;
      font-size: 10px;
      line-height: 12px;
    }
  }
  &__details-line {
    width: 1px;
    height: .2133rem;
    background-color: rgba(256, 256, 256, .5);
    margin: 0 .16rem;
  }
  &__details-amount {
    font-family: Roboto;
    color: #FFF;
    font-size: 10px;
    font-weight: 400;
    span {
      font-weight: 700;
      color: #FA6338;
    }
  }
  &__details-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    animation: unpaid-breathe 1s infinite;
    .details-right__txt {
      font-size: 12px;
      width: 2.16rem;
      height: .64rem;
      padding: .1067rem .2133rem;
      text-align: center;
      color: #000;
      background-color: #fff ;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .details-right__freeshipping {
      position: relative;
      font-size: 12px;
      height: .32rem;
      margin-right: -.1333rem;
    }
    .details-right__freeshipping-txt {
      height: .32rem;
      line-height: .32rem;
      padding: 0 .1067rem;
      background-color: #198055;
      color: #fff;
      font-size: 10px;
      display: inline-block;
    }
    .details-right__freeshipping-tips {
      position: absolute;
      bottom: -.1867rem;
      border-bottom: .1867rem solid #16533A;
      right: 0;
      border-left: 0 solid transparent;
      border-right: .1867rem solid transparent;
      transform: rotate(90deg);
      z-index: -1;
      transform: translateZ(-1px);
    }
  }
  &__details-shipping {
    margin-right: .1333rem;
  }
  &__wrap-close {
    position: absolute;
    right: 0;
    top: -0.2133rem;
    width: .4267rem;
    height: .2133rem;
  }
  &__wrap-imgs {
    min-width: 1.0667rem;
    height: 1.28rem;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: .1333rem;
    .imgs-main {
      width: 1.0667rem;
      height: 100%;
      border-radius: .0533rem;
      border: 1px solid rgba(255, 255, 255, 0.80);
    }
    .imgs-more {
      width: .0533rem;
      height: 1.1467rem;
      border-radius: 0 .0533rem .0533rem 0;
      background: #f5e3db;
    }
    .imgs-equity {
      position: absolute;
      // left: 1px;
      // bottom: 1px;
      left: 0px;
      bottom: -1px;
      width: 1.0133rem;
      height: .32rem;
      line-height: .32rem;
      text-align: center;
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      border-radius: 0 0 .0533rem .0533rem;
      background: rgba(34, 34, 34, 0.60);
    }
    .imgs-equity__fade {
      width: 100%;
      display: flex;
      justify-content: center;
      // background: rgba(255, 255, 255, .3);
      background: rgba(0,0,0,.8);
    }
  }
}
// 消失动画
.unpaid-fade-leave-active {
  transition: opacity .4s ease, transform .5s ease;
}
.unpaid-fade-leave-to {
  transform: translateX(7.3867rem);
  opacity: 0;
}
.min-fade-leave-active {
  transition: opacity .4s ease, transform .5s ease;
}
.min-fade-leave-to {
  transform: translateX(1.3867rem);
  opacity: 0;
}

// 弹出动画
.unpaid-fade-enter-active {
  transition: transform 1s ease;
}
.unpaid-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}
.unpaid-fade-enter {
  transform: translateX(7.3867rem);
  opacity: 1;
}
.min-fade-enter-active {
  transition: transform 5s ease;
}
.min-fade-enter {
  transform: translateX(7.3867rem);
}
.min-fade-enter-to {
  transform: translateX(0);
}

@keyframes unpaid-breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}
</style>
