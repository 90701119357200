const msgFormats = (tagName) => [
  `%c[DEBUG ${tagName}]%c`,
  'color: #ff0; background: #000; padding: 5px;',
  'color: #000;',
]

/**
 * 调试日志收集器，方便开发定位问题
 * 
 * @param {string} tagName 
 */
export function createDebugRecorder(tagName) {
  // 收集调试输出信息
  let _debugMsgs = []

  const debugRecord = (...args) => {
    _debugMsgs.push(args)
  }
  /** @param {() => any[]} getter */
  const addDebugMsgGetter = (getter) => {
    _debugMsgs.push(getter)
  }
  const clearMsg = () => {
    _debugMsgs = []
  }
  const showDebugMsgs = () => {
    for (const msgOrGetter of _debugMsgs) {
      if (typeof msgOrGetter === 'function') {
        console.log(
          ...msgFormats(tagName),
          ...msgOrGetter(),
        )
      } else {
        // typeof msgOrGetter === 'string'
        console.log(
          ...msgFormats(tagName),
          ...msgOrGetter,
        )
      }
    }
  }
  const dispose = () => {
    // 防止内存泄漏
    _debugMsgs = []
  }

  const instance = {
    debugRecord,
    clearMsg,
    showDebugMsgs,
    addDebugMsgGetter,
    dispose,
  }

  // 创建调试输出收集器
  if (typeof window !== 'undefined') {
    const globalInstance = window.__dev_debug_recorders__
    if (!globalInstance) {
      window.__dev_debug_recorders__ = {}
    }
    window.__dev_debug_recorders__[tagName] = instance
  }

  return instance
}
