<template>
  <div
    class="count-down__wrap"
    :class="[theme == 'white' ? 'count-down__wrap-white' : '']"
  >
    <div class="count-down__time">
      {{ hours }}
    </div>
    <div class="count-down__delimiter">
      :
    </div>
    <div class="count-down__time">
      {{ minutes }}
    </div>
    <div class="count-down__delimiter">
      :
    </div>
    <div class="count-down__time">
      {{ seconds }}
    </div>
  </div>
</template>

<script>
import { CountDown } from '@shein/common-function'
export default {
  name: 'CountDown',
  props: {
    timeSeconds: {
      type: Number,
      default: 0
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      updateFlag: false
    }
  },
  created () {
    this.setTime()
  },
  methods: {
    setTime () {
      const timer = new CountDown({
        seconds: this.timeSeconds,
        countFunc: (timeObj) => {
          const { H, M, S } = timeObj
          this.hours = H
          this.minutes = M
          this.seconds = S
          if (!this.updateFlag && H == '00' && M == '00' && ['00', '02', '04', '06'].includes(S)) {
            this.updateFlag = true
            this.$emit('update-data')
          }
          this.$emit('count-func')
        },
        endFunc: () => {
          this.$emit('countdown-end')
          timer.clear()
        }
      })
      timer.start()
      this.$once('hook:beforeDestroy', () => {
        timer.clear()
      })
    }
  }
}
</script>

<style scoped lang="less">
.count-down {
  &__wrap {
    /* rtl:begin:ignore */
    direction: ltr;
    /* rtl:end:ignore */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 10px;
    font-family: "SF UI Text";
  }
  
  &__wrap-white {
    font-weight: 700;
    .count-down__time {
      background-color: rgba(255, 255, 255, 0);
    }
    .count-down__delimiter {
      margin: 0;
    }
  }

  &__time {
    width: .3733rem;
    height: .3733rem;
    padding: .0267rem;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__delimiter {
    color: #fff;
    font-weight: 700;
    margin: 0 .0533rem;
  }
}
</style>
